.canvasMatrix {
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  .matrix {
    width: 100%;
    height: 100%;
    background: $dark;
    display: block;
    opacity: .4;
    object-fit: cover; } }




.headerMenu {
  border-bottom: 1px solid $aqua;
  padding: 20px 0;
  backdrop-filter: blur(10px);
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 10;
  left: 0;
  transform: translateY(-100%);
  &::before {
    content: 'Donde las ideas se transforman en experiencias digitales';
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $aqua;
    color: $dark;
    font-size: 10px;
    display: inline-flex;
    width: fit-content;
    height: 20px;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    padding: 0 20px;
    clip-path: polygon(96% 0, 100% 35%, 100% 100%, 0 100%, 0 0); }
  img {
    width: 130px;
    opacity: 0; }
  @media screen and ( max-width: 768px ) {
    &::before {
      display: none; }
    img {
      width: 130px; } } }



.footer {
  padding: 70px 0;
  background-color: $dark2;
  border-top: 1px solid $aqua;
  a {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    &.in {
      background: url('../img/vector/icon_instagram.svg') center / contain no-repeat; }
    &.fb {
      background: url('../img/vector/icon_facebook.svg') center / contain no-repeat; } }
  @media screen and ( max-width: 768px ) {
    span, p {
      font-size: 13px; }
    p {
      margin-bottom: 20px !important; } } }


.floatMobile {
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  height: 75px;
  background-color: rgba($dark2,.7);
  backdrop-filter: blur(10px);
  border-top: 1px solid $aqua;
  display: none;
  justify-content: space-between;
  align-items: center;
  transform: translateY(100%);
  a, button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $light;
    height: 100%;
    width: 100%;
    text-decoration: none;
    background-color: transparent;
    border: none;
    font-size: 13px;
    padding: 0;
    opacity: .6;
    &::before {
      content: '';
      width: 40px;
      height: 40px; }
    &.plan {
      &::before {
        background: url('../img/vector/footer_price.svg') center / 26px no-repeat; } }
    &.portfolio {
      &::before {
        background: url('../img/vector/footer_portfolio.svg') center / 26px no-repeat; } }
    &.whatsapp {
      &::before {
        background: url('../img/vector/wsp_light.svg') center / 26px no-repeat; } }
    &:nth-child(2) {
      border-left: 1px solid $aqua;
      border-right: 1px solid $aqua; }
    &:active {
      background-color: $aqua;
      color: $dark;
      &::before {
        filter: brightness(.3); } } }
  @media screen and ( max-width: 768px ) {
    display: flex; } }


.floatWsp {

  position: fixed;
  bottom: 0;
  right: 0;
  margin: 10px;
  display: flex;
  align-items: center;
  z-index: 2;
  span {
    background-color: $main;
    display: inline-flex;
    padding: 2px 10px;
    color: $light;
    font-size: 13px;
    margin-right: 7px;
    border-radius: 5px; }
  a {
    border: 1px dotted rgba($aqua,.3);
    width: 60px;
    height: 60px;
    background: transparent url('../img/vector/wsp_green.svg') center / 40px no-repeat;
    @media screen and ( max-width: 768px ) {
      display: none; } } }


.footerSection {
  @media screen and ( max-width: 768px ) {
    border-top: 1px solid $aqua;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    background-color: $dark;
    .text-aqua {
      font-size: 13px;
      margin: 0 0 10px 0 !important; }
    a,button {
      font-size: 14px; } } }



.sendEmail {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  .modalThanks {
    width: 350px;
    height: auto;
    padding: 50px;
    background-color: $dark;
    color: $light;
    text-align: center; } }

.linksFooter {
  display: flex;
  margin-top: 20px;
  a {
    display: block;
    white-space: nowrap;
    color: $aqua;
    padding: 0 10px;
    position: relative;
    margin: 0;
    width: auto; }
  @media screen and ( max-width: 768px ) {
    display: block;
    text-align: center;
    margin: 40px 0; } }

