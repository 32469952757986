.accordion {
  backdrop-filter: blur(5px);
  .accordion-item {
    border-radius: 0;
    background-color: transparent;
    border: 1px solid $aqua;
    margin-bottom: 10px;
    .accordion-button {
      background-color: transparent;
      color: $aqua;
      height: 70px;
      border-radius: 0;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid $aqua;
      font-size: 14px;
      &::after {
        content: '';
        width: 40px;
        height: 40px;
        background: url('../img/vector/arrow_next.svg') center/ 22px no-repeat;
        transform: rotate(-90deg); }
      &:hover {
        background-color: rgba($aqua,.1); }
      &.collapsed {
        &::after {
          transform: rotate(90deg); } } }
    .accordion-collapse {
      .accordion-body {
        p {
          color: $light;
          font-size: 14px; } } } } }
