.slidePortfolio {
  position: relative;
  .info {
    h5 {
      font-family: $bold;
      color: $aqua;
      font-size: 30px;
      margin-bottom: 20px; }
    p {
      margin-bottom: 30px;
      padding-left: 80px; } }
  .number {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    span {
      font-family: $bold;
      font-size: 170px;
      display: block;
      text-align: center;
      position: absolute;
      bottom: -100px; } }
  .swiper-button-prev, .swiper-button-next {
    width: 70px;
    height: 70px;
    &::after {
      display: none; } }
  .swiper-button-prev {
    background: url('../img/vector/arrow_prev.svg') center / 40px no-repeat; }
  .swiper-button-next {
    background: url('../img/vector/arrow_next.svg') center / 40px no-repeat; }
  @media screen and ( max-width: 768px ) {
    padding-bottom: 90px;
    .info {
      h5 {
        font-size: 20px; }
      p {
        padding: 0; }
      .btn-line {
        padding: 10px 20px;
        font-size: 14px;
        &::after {
          width: 15px;
          height: 15px; } } }
    .number {
      display: none; }
    .swiper-button-prev, .swiper-button-next {
      transform: translateY(110px); } } }



.cardService {
  backdrop-filter: blur(5px);
  background-color: transparent;
  border: 1px solid rgba($aqua,.2);
  color: $light;
  display: block;
  width: 100%;
  position: relative;
  padding: 40px;
  margin-bottom: 35px;
  .lines {
    position: absolute;
    width: 10px;
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    left: -5px;
    top: 10px;
    div {
      width: 10px;
      height: 1px;
      background: $aqua; } }

  .text-end {
    display: flex;
    justify-content: flex-end;
    h5 {
      display: flex;
      color: $light;
      background-color: $dark;
      width: fit-content;
      padding: 4px 20px 4px 40px;
      justify-content: flex-end;
      position: relative;
      clip-path: polygon(4% 0, 100% 0, 100% 100%, 0 100%, 0 30%);
      border: 1px solid rgba($light,.1);
      margin-bottom: 15px;
      overflow: hidden;
      &::after {
        content: '';
        width: 180px;
        height: 10px;
        background-color: orange;
        border-radius: 50%;
        right: -50px;
        top: 11px;
        position: absolute;
        pointer-events: none;
        mix-blend-mode: lighten;
        opacity: .6;
        filter: blur(5px);
        transform: translateX(140px);
        transition: all .2s;
        transition-delay: .2s; }
      span {
        font-family: $bold; }
      .line {
        position: absolute;
        width: 20px;
        height: 5px;
        bottom: 0;
        left: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: .3;
        div {
          width: 1px;
          height: 100%;
          background-color: $light; } } } }
  .row {
    text-align: start;
    p {
      font-size: 14px;
      letter-spacing: 2px;
      margin-bottom: 0; }
    .price {
      font-size: 30px;
      color: $aqua; } }
  &::after {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    background-color: $gold;
    animation: lighting 3s ease-in-out infinite;
    opacity: 0;
    transition: all .2s;
    @keyframes lighting {
      0% {
        box-shadow: 0 0 3px 1px rgba($gold,.1); }
      50% {
        box-shadow: 0 0 3px 1px rgba($gold,1); }
      100% {
        box-shadow: 0 0 3px 1px rgba($gold,.1); } } }

  &:hover {
    border-right: 1px solid rgba($gold,.3);
    border-bottom: 1px solid rgba($gold,.3);
    box-shadow: 0 5px 7px rgba($gold,.2);
    img {
      filter: brightness(1.1); }
    &::after {
      opacity: 1; }
    .text-end {
      h5 {
        &::after {
          transform: translateX(0); } } } }

  @media screen and ( max-width: 768px ) {
    margin-bottom: 20px;
    padding: 20px;
    h5 {
      background-color: transparent !important;
      font-size: 15px; }
    p, .price {
      text-align: center; }
    .btn-line-blue {
      justify-content: center;
      font-size: 14px; } } }

.commentHeader {
  position: relative;
  margin-top: 50px;
  span {
    position: absolute;
    right: 20px;
    bottom: 70px;
    color: $aqua;
    letter-spacing: 2px;
    font-size: 14px;
    @media screen and ( max-width: 768px ) {
      display: block;
      width: 200px;
      text-align: end;
      bottom: 32px;
      right: 0; } } }


.slideBenefits {
  .slick-dots {
    li {
      border-radius: 4px;
      width: 6px;
      height: 6px;
      overflow: hidden;
      transition: all .3s;
      margin-top: 0;
      button {
        width: 100%;
        height: 100%;
        background: rgba($main,.2);
        transition: all .3s;
        padding: 0;
        &::before {
          display: none; } }
      &.slick-active {
        width: 25px;
        box-shadow: 0 0 13px 1px $aqua;
        button {
          background-color: $aqua; } } } } }

.slideComments {
  h5 {
    font-family: $bold;
    text-transform: uppercase; }
  span {
    color: rgba($main,.5);
    font-size: 13px; }
  p {
    margin: 0;
    text-align: end;
    padding-right: 40px; }
  .slick-dots {
    bottom: 0px;
    width: fit-content;
    background-color: $dark;
    padding: 10px;
    border-radius: 5px;
    li {
      border-radius: 4px;
      width: 6px;
      height: 6px;
      overflow: hidden;
      transition: all .3s;
      margin-top: 0;
      button {
        width: 100%;
        height: 100%;
        background: rgba($main,.2);
        transition: all .3s;
        padding: 0;
        &::before {
          display: none; } }
      &.slick-active {
        width: 25px;
        box-shadow: 0 0 13px 1px $gold;
        button {
          background-color: $gold; } } } }

  .col-md-4 {
    border-right: 1px solid $aqua; }
  @media screen and ( max-width: 768px ) {
    h5 {
      margin-bottom: 0; }
    .col-md-4 {
      border: none; }
    p {
      padding: 0;
      margin-top: 20px; }
    .slick-dots {
      top: 0;
      right: 0;
      height: fit-content; } } }


.viewProject {
  border: 2px solid $main;
  border-radius: 10px;
  perspective: 100px;
  box-shadow: 0 0 5px rgba($main,1);
  animation: projectRotate 15s linear infinite;
  transition: all .3s;
  padding: 4px; }
@keyframes projectRotate {
  0% {
    transform: rotateY(0deg) rotateX(-5deg) rotateZ(-1deg); }
  100% {
    transform: rotateY(360deg) rotateX(-5deg) rotateZ(-1deg); } }

