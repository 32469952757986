// ====================== Fonts ======================
@font-face {
  font-family: 'NewShapeBold';
  src: url('./fonts/Audiowide-Regular.ttf') format("truetype"); }
$bold: 'NewShapeBold';
@font-face {
  font-family: 'NewShapeBook';
  src: url('./fonts/NewShape-Book.ttf') format("truetype"); }
$semibold: 'NewShapeBook';
@font-face {
  font-family: 'RobotoMono';
  src: url('./fonts/RobotoMono-Regular.ttf') format("truetype"); }
$regular: 'RobotoMono';



$dark: #2a2d31;
$dark2: #242326;
$aqua: #5ff4c4;
$main: #6099bd;
$light: #fffcf2;
$gold: #ffb152;
$error: #ff8c8c;

body {
  overflow-x: hidden;
  background-color: $dark;
  color: $light;
  font-size: 15px;
  font-family: $regular;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  &::-webkit-scrollbar-track {
    background-color: $dark;
    border-radius: 10px; }
  &::-webkit-scrollbar-thumb {
    background-color: $aqua;
    border-radius: 10px; } }

html {
  scroll-behavior: smooth; }


button, input, textarea {
  transition: all .3s;
  &:focus {
    outline: none; } }

* {
  &::selection {
    background: $aqua;
    color: $dark; } }

a,button,input {
  transition: all .3s; }

h1,h2,h3, .text-bold {
  font-family: $bold; }

h4,h5,h6,strong {
  font-family: $semibold; }

div, p, span, table {
  font-family: $regular; }

.bg-dark2 {
  background-color: $dark2; }

.text-aqua {
  color: $aqua; }
.text-main {
  color: $main; }

.text-line-aqua {
  color: transparent;
  text-fill-color: transparent;
  -webkit-text-stroke: 1.3px $aqua;
  text-stroke: 1.3px $aqua; }

.labelAqua {
  display: inline-flex;
  padding: 5px 10px;
  color: $dark;
  background-color: $aqua; }

.btn-line-blue {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $light;
  background-color: transparent;
  width: 100%;
  height: 40px;
  font-family: $regular;
  font-size: 17px;
  border: none;
  text-decoration: underline; }

.bg-dark {
  background-color: $dark2 !important; }

.subtitle {
  position: relative;
  width: fit-content;
  padding: 10px 0;
  &::before {
    content: '';
    width: 40%;
    height: 3px;
    background-color: $aqua;
    position: absolute;
    bottom: 0;
    left: 0; }
  @media screen and ( max-width: 768px ) {
    display: flex;
    justify-content: center;
    &::before {
      width: 50px;
      left: auto;
      bottom: -10px; } } }



.btn-line {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 50px;
  border: 1px solid $aqua;
  color: $light;
  text-decoration: none;
  background-color: transparent;
  padding: 4px 20px 4px 30px;
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    background: url('../img/vector/arrow_next.svg') center / 20px no-repeat;
    margin-left: 10px;
    transition: all .2s; }
  &:hover {
    color: $aqua;
    &:after {
      transform: translateX(5px); } } }


.btnAqua {
  width: 100%;
  height: 50px;
  color: $dark;
  background-color: $aqua;
  border: 1px solid $aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: white;
    box-shadow: 0 3px 10px 3px rgba($aqua,.2); } }
.btnLine {
  width: 100%;
  height: 50px;
  color: $light;
  background-color: transparent;
  border: 1px solid $light;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    color: $light;
    background-color: transparent; } }


.error {
  color: $error;
  display: block;
  margin-top: 10px;
  font-size: 13px;
  transform: translateY(-4px); }

.listPlans {
  margin-bottom: 30px;
  list-style: none;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 35px;
    border-bottom: 1px dotted rgba($light,.1);
    color: rgba($light,.7);
    font-size: 14px;
    background-color: rgba(black,.05);
    position: relative;
    &::before {
      content: '';
      width: 20px;
      height: 10px;
      position: absolute;
      background: url('../img/vector/arrow_back.svg') center / contain no-repeat;
      transform: rotate(180deg);
      left: 6px; } } }
