.pHero {
  text-align: end;
  position: relative;
  border-right: 1px solid $light;
  padding-right: 20px;
  backdrop-filter: blur(3px);
  &::before {
    content: '';
    width: 240px;
    height: 240px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid rgba($light,.1);
    right: -50%; }
  @media screen and ( max-width: 768px ) {
    border: none;
    padding: 0;
    font-size: 14px;
    &::before {
      display: none; } } }





.content-slide {
  position: relative;
  width: 77%;
  height: auto;
  aspect-ratio: 1/1;
  .man {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
    z-index: 1; }
  @media screen and ( max-width: 768px ) {
    width: 100%; } }


.slide1 {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  position: relative;
  img {
    width: 30%;
    position: absolute;
    animation: floating 4s ease-in-out infinite;
    &.s1 {
      left: 30%;
      top: 5%;
      width: 40%;
      animation-delay: .1s;
      animation-duration: 5s; }
    &.s2 {
      width: 35%;
      left: 20%;
      animation-duration: 3.5s;
      animation-delay: .2s; }
    &.s3 {
      bottom: 30%;
      left: 0%;
      width: 45%;
      animation-duration: 5.5s;
      animation-delay: .3s; }
    &.s4 {
      right: 20%;
      top: 35%;
      width: 25%;
      animation-duration: 5.3s;
      animation-delay: .2s; }
    &.s5 {
      bottom: 10%;
      left: 10%;
      width: 35%;
      animation-duration: 3.5s;
      animation-delay: .2s; }
    &.s6 {
      right: 14%;
      top: 5%;
      animation-duration: 4.5s;
      animation-delay: .12s;
      width: 20%; }
    &.s7 {
      animation-duration: 4.8s;
      animation-delay: .3s;
      right: 5%;
      top: 20%;
      width: 30%; }
    &.s8 {
      right: 8%;
      bottom: 24%;
      animation-delay: .2s;
      animation-duration: 5s;
      width: 23%; }
    &.s9 {
      bottom: 10%;
      animation-duration: 4.7s;
      animation-duration: 4.5s;
      right: 15%; } } }


@keyframes floating {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-8px); }
  100% {
    transform: translateY(0px); } }


.moveLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $aqua;
  border-bottom: 1px solid $aqua;
  backdrop-filter: blur(2px);
  padding: 30px 0;
  text-transform: uppercase;
  overflow-x: auto;
  margin: 0;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px; }
  span {
    font-size: 60px;
    width: 100%;
    font-family: $bold;
    color: $aqua;
    display: block;
    white-space: nowrap; }
  @media screen and ( max-width: 768px ) {
    span {
      font-size: 26px; } } }


.slide2 {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  position: relative;
  .rotate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      &.s21 {
        animation: rts1 27s linear infinite; }
      &.s22 {
        animation: rts2 24s linear infinite; }
      &.s23 {
        animation: rts3 20s linear infinite; } } }
  .phone {
    position: absolute;
    bottom: 0%;
    right: 14%;
    width: 280px;
    @media screen and ( max-width: 768px ) {
      width: 200px; } } }


@keyframes rts1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
@keyframes rts2 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-360deg); } }
@keyframes rts3 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.principalTitle {
  line-height: 45px;
  .labelAqua {
    margin-top: 10px; }
  @media screen and ( max-width: 768px ) {
    line-height: 35px; } }



.h-anim {
  transform: translateY(-30px);
  opacity: 0; }


.Typewriter__cursor {
  color: $aqua; }

.content-slide {
  opacity: 0;
  .mn {
    opacity: 0; } }

.experience {
  transform: scale(0);
  opacity: 0;
  width: 90px; }
