.contentPlan {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  top: 0;
  left: 0;
  z-index: 11;
  pointer-events: none;
  overflow-y: auto;
  .slices {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    div {
      width: 100%;
      height: calc( 100vh / 4 );
      background: rgba($dark2,.73);
      backdrop-filter: blur(15px);
      transform: translateX(100vw); } }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    opacity: 0;
    padding-bottom: 40px;
    p {
      font-size: 14px; }
    @media screen and ( max-width: 768px) {
      padding-bottom: 160px; } }

  &.active {
    pointer-events: auto; } }

.backPage {
  background-color: transparent;
  color: $light;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  &::before {
    content: '';
    width: 40px;
    height: 20px;
    margin-right: 7px;
    background: url('../img/vector/arrow_back.svg') center / contain no-repeat; } }
.arrowBack {
  position: relative;
  border: 1px dotted rgba($light,.3) !important;
  &::before {
    content: '';
    width: 30px;
    height: 15px;
    margin-right: 7px;
    background: url('../img/vector/arrow_back.svg') center / contain no-repeat; } }

.listInclude {
  padding: 12px;
  margin: 10px 0;
  font-size: 13px;
  text-align: center;
  background-color: rgba(black,.07); }

.headerPlan {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    margin-right: 10px; } }

.inputStyle {
  margin-bottom: 10px;
  input {
    width: 100%;
    background-color: rgba(black,.1);
    border: 1px solid rgba($light,.1);
    height: 50px;
    padding: 5px 15px;
    color: $light; }
  &.loading {
    opacity: .5;
    pointer-events: none;
    input {
      color: $aqua; } } }

.loadingEffect {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: block;
    width: 40px;
    height: 40px;
    border: 4px solid $dark;
    border-left-color: $aqua;
    border-bottom-color: $aqua;
    border-radius: 50%;
    animation: loadingeffect .6s linear infinite;
    @keyframes loadingeffect {
      0% {
        transform: rotate(0deg); }
      100% {
        transform: rotate(360deg); } } } }




.inputCheck {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 55px;
  position: relative;
  cursor: pointer;
  input {
    display: none; }
  strong, span {
    pointer-events: none;
    display: block;
    color: $light;
    opacity: .7; }
  strong {
    color: $main;
    font-size: 13px;
    margin-left: 4px; }
  &::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 10px;
    border: 1px solid rgba($main,.1);
    background: rgba($dark2,.4) url('../img/vector/checked.svg') center / 0px no-repeat;
    transition: all .3s; }
  &.active {
    strong, span {
      opacity: 1; }
    &::before {
      background: $main url('../img/vector/checked.svg') center / 20px no-repeat; } } }


.totalPrice {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: $aqua;
    letter-spacing: 2px;
    font-size: 11px; }
  strong {
    font-size: 22px;
    font-family: $regular; } }


.wspLink {
  text-align: center;
  background: rgba($dark2,.4);
  border: 1px dotted rgba($aqua,.3);
  margin-top: 50px;
  padding: 20px;
  letter-spacing: 1px;
  box-shadow: 0 0 15px rgba($aqua,.05);
  a {
    display: inline-flex;
    margin-left: 5px;
    color: $aqua;
    text-decoration: none; } }
