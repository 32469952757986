.errorPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  div {
    h1 {}
    p {}
    .btnAqua {
      width: 300px; } } }
